import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

// 슬라이드 데이터 타입
type Slide = {
  id: number;
  image: string;
  alt: string;
};

const slides: Slide[] = [
  { id: 1, image: "/logo192.png", alt: "Slide 1" },
  { id: 2, image: "/asset/horizontal-logo.png", alt: "Slide 2" },
  { id: 3, image: "/asset/vertical-logo.png", alt: "Slide 2" },
];

interface SlideWrapperProps {
  currentSlide: number;
}

const SlideshowRecorder = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [isPlaying, setIsPlaying] = useState(true); // 슬라이드 재생 상태

  useEffect(() => {
    if (!isPlaying) return; // 재생 중이 아니면 실행하지 않음

    const interval = setInterval(() => {
      setCurrentSlide((prev) => {
        if (prev + 1 >= slides.length) {
          setIsPlaying(false); // 모든 이미지가 다 보여지면 재생 멈춤
          return prev; // 마지막 이미지를 유지
        }
        return prev + 1;
      });
    }, 5000); // 3초 간격

    return () => clearInterval(interval);
  }, [isPlaying]);

  // // 자동 전환 로직
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentSlide((prev) => (prev + 1) % slides.length);
  //   }, 3000); // 3초 간격
  //   return () => clearInterval(interval);
  // }, []);

  const [isRecording, setIsRecording] = useState(false);
  let mediaRecorder: MediaRecorder | null = null;
  let recordedChunks: Blob[] = [];

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      console.log("Recording started");
    } catch (error) {
      console.error("Error accessing display media:", error);
    }
  };

  const stopRecording = () => {
    console.log("stop1");
    if (mediaRecorder) {
      console.log("stop2");
      mediaRecorder.stop();
      console.log("stop3");
      mediaRecorder.onstop = () => {
        console.log("stop4");
        const videoBlob = new Blob(recordedChunks, { type: "video/webm" });
        const videoURL = URL.createObjectURL(videoBlob);
        console.log("Recording saved:", videoURL); // 여기에 비디오 URL을 저장하거나 처리할 수 있습니다.
      };
      setIsRecording(false);
      console.log("Recording stopped");
    } else {
      console.log("없다;");
      setIsRecording(false);
    }
  };
  return (
    <Container>
      <div>recorder</div>
      <div>
        <button
          onClick={() => {
            console.log("Button clicked");
            isRecording ? stopRecording() : startRecording();
          }}
        >
          {isRecording ? "Stop Recording" : "Start Recording"}
        </button>
      </div>
      <SlideWrapper currentSlide={currentSlide}>
        {slides.map((slide) => (
          <SlideImg key={slide.id}>
            <img src={slide.image} alt={slide.alt} />
          </SlideImg>
        ))}
      </SlideWrapper>
    </Container>
  );
};

export default SlideshowRecorder;

// 스타일 컴포넌트
const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 720px;
  height: 100%;
  overflow: hidden;
  margin: auto;
  box-shadow: 0 0 20px #82828226;
`;

const SlideWrapper = styled.div<SlideWrapperProps>`
  display: flex;
  transform: ${({ currentSlide }) => `translateX(-${currentSlide * 100}%)`};

  transition: transform 0.5s ease-in-out;
  margin: 0 auto;
`;

const SlideImg = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex; /* Flexbox 사용 */
  justify-content: center; /* 가로 가운데 정렬 */
  align-items: center; /* 세로 가운데 정렬 */

  img {
    max-width: 300px; /* 부모 컨테이너의 너비를 초과하지 않도록 설정 */
    max-height: auto; /* 부모 컨테이너의 높이를 초과하지 않도록 설정 */
    object-fit: contain; /* 이미지 비율을 유지하며 컨테이너에 맞게 조정 */
  }
`;
