import styled from "styled-components";
import Layout from "../../components/Layout";
import { useState } from "react";
import ImgList from "./components/ImgList";
import DetailList from "./components/DetailList";

const dummy_data = [
  { no: 100, img: "/asset/IMG_7225.JPG" },
  { no: 200, img: "/asset/IMG_7225.JPG" },
  { no: 300, img: "/asset/IMG_7225.JPG" },
  { no: 400, img: "/asset/IMG_7225.JPG" },
  { no: 500, img: "/asset/IMG_7225.JPG" },
  { no: 600, img: "/asset/IMG_7225.JPG" },
  { no: 700, img: "/asset/IMG_7225.JPG" },
  { no: 800, img: "/asset/IMG_7225.JPG" },
  { no: 900, img: "/asset/IMG_7225.JPG" },
  { no: 1000, img: "/asset/IMG_7225.JPG" },
];

const List = () => {
  const [visibleDetailView, setVisibleDetailView] = useState(false);

  const RenderImgList = (visibleDetailView: boolean) => {
    if (visibleDetailView) {
      return <DetailList setVisibleDetailView={setVisibleDetailView} />;
    } else {
      return <ImgList setVisibleDetailView={setVisibleDetailView} />;
    }
  };

  return (
    <Container>
      <Layout />
      <InnerContainer>{RenderImgList(visibleDetailView)}</InnerContainer>
    </Container>
  );
};
export default List;

const InnerContainer = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
  padding: 16px 24px;
  box-sizing: border-box;
  box-shadow: 0 0 20px #82828226;
`;
