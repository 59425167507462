import styled from "styled-components";

interface ImgDetailListProps {
  setVisibleDetailView: React.Dispatch<React.SetStateAction<boolean>>;
}

const dummy_detailList = [
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
  { pno: 1, img: "/asset/IMG_2979.JPG", content: "test test" },
];

const DetailList = ({ setVisibleDetailView }: ImgDetailListProps) => {
  return (
    <GridContainer>
      {dummy_detailList.map((data: any, index: number) => (
        <GridItem
          key={index}
          //   onClick={() => {
          //     onChangeVisibleState();
          //   }}
        >
          <img src={`${data.img}`} alt="Sample" />
          {/* <div>{data.no}</div> */}
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default DetailList;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2열 레이아웃 */
  gap: 10px; /* 각 아이템 사이의 간격 */
  width: 100%;
  height: auto;
  margin: auto;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  font-size: 1.2rem;
  /* max-width: 150px;
      max-height: 150px; */
  gap: 4px;
  img {
    /* max-width: 150px;
        max-height: 127.5px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
