import styled from "styled-components";
import Layout from "../../components/Layout";

const View = () => {
  return (
    <Container>
      <Layout />
    </Container>
  );
};

export default View;

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
  padding: 16px 24px;
  box-sizing: border-box;
  box-shadow: 0 0 20px #82828226;
`;
