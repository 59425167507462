import styled from "styled-components";
import Layout from "../../components/Layout";

const Main = () => {
  return (
    <Container>
      <Layout />
      <InnerContainer>
        <Button
          onClick={() => {
            window.location.href = "/list"; // '='을 사용해 URL 경로를 할당
          }}
        >
          보기
        </Button>
        <Button>등록</Button>
        <Button>수정</Button>
        <Button>삭제</Button>
      </InnerContainer>
    </Container>
  );
};

export default Main;

const Button = styled.div`
  width: 100%;
  /* height: 100px; */
  padding: 24px;
  background-color: blue;
  color: white;
  border-radius: 16px;
  box-sizing: border-box; /* 패딩 포함 크기 계산 */
  text-align: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 16px 24px;
  box-sizing: border-box;
`;
