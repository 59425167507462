import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Show from "./pages/show/Show";
import Main from "./pages/Main/Main";
import List from "./pages/List/List";
import View from "./pages/View/View";
import SlideshowRecorder from "./pages/record/SlideshowRecorder";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route path="/list" element={<List />}></Route>
      <Route path="/show" element={<Show />}></Route>
      <Route path="/view" element={<View />}></Route>
      <Route path="/record" element={<SlideshowRecorder />}></Route>
    </Routes>
  );
}

export default App;
