import styled from "styled-components";

interface ImgListProps {
  setVisibleDetailView: React.Dispatch<React.SetStateAction<boolean>>;
}

const dummy_data = [
  { no: 100, img: "/asset/IMG_7225.JPG" },
  { no: 200, img: "/asset/IMG_7225.JPG" },
  { no: 300, img: "/asset/IMG_7225.JPG" },
  { no: 800, img: "/asset/IMG_7225.JPG" },
  { no: 400, img: "/asset/IMG_7225.JPG" },
  { no: 500, img: "/asset/IMG_7225.JPG" },
  { no: 600, img: "/asset/IMG_7225.JPG" },
  { no: 700, img: "/asset/IMG_7225.JPG" },
  { no: 900, img: "/asset/IMG_7225.JPG" },
  { no: 1000, img: "/asset/IMG_7225.JPG" },
];

const ImgList = ({ setVisibleDetailView }: ImgListProps) => {
  const onChangeVisibleState = () => {
    setVisibleDetailView(true);
  };
  return (
    <GridContainer>
      {dummy_data.map((data: any, index: number) => (
        <GridItem
          key={index}
          onClick={() => {
            onChangeVisibleState();
          }}
        >
          <img src={`${data.img}`} alt="Sample" />
          <div>{data.no}</div>
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default ImgList;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2열 레이아웃 */
  gap: 10px; /* 각 아이템 사이의 간격 */
  width: 100%;
  height: auto;
  margin: auto;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  font-size: 1.2rem;
  /* max-width: 150px;
  max-height: 150px; */
  gap: 4px;
  img {
    /* max-width: 150px;
    max-height: 127.5px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
