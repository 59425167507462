import styled from "styled-components";

function calculateDaysSince(anniversaryDate: string): number {
  // 한국 시간(KST) 기준으로 Date 객체 생성
  const startDate = new Date(`${anniversaryDate}T00:00:00+09:00`); // 한국 시간 명시
  const now = new Date();

  // 현재 날짜를 한국 시간(KST)으로 변환
  const KST_OFFSET = 9 * 60; // 분 단위
  const nowInKST = new Date(
    now.getTime() + now.getTimezoneOffset() * 60000 + KST_OFFSET * 60000
  );

  console.log(startDate, nowInKST);
  // 날짜를 자정으로 정렬
  startDate.setHours(0, 0, 0, 0);
  nowInKST.setHours(0, 0, 0, 0);

  // 차이 계산
  const timeDifference = nowInKST.getTime() - startDate.getTime();

  // 일수 반환
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
}

const anniversary = "2022-03-27"; // Replace with your desired date
const daysSince = calculateDaysSince(anniversary);

const Layout = () => {
  return (
    <LayoutContainer>
      <div>wistory</div>
      <div>희지와 {daysSince}일째</div>
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
  box-sizing: border-box;
`;
